import React, { Component } from "react"

import Footer from '../components/footer/';
import Header from '../components/header/';
import style from '../templates/home/home.module.scss';
import SEO from '../components/seo';
import Background from '../components/background';
import Offcanvas from '../components/offcanvas/';
class NotFoundPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isOpen: false,
		}
		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
	}

	open() {
		this.setState({ isOpen: true });
	}

	close() {
		this.setState({ isOpen: false });
	}

	render() {
		return (
			<>
				<SEO>
					<link rel="stylesheet" href="https://use.typekit.net/uac4quh.css" />
				</SEO>
				<Offcanvas isOpen={this.state.isOpen} offcanvasOpen={this.open} offcanvasClose={this.close}>
					<Header spacer={false} offcanvasOpen={this.open} offcanvasClose={this.close} offcanvasState={this.state.isOpen} />
					<Background className={style.background} image="Danos-Default-Placeholder-v2.jpg">
						<div className="grid-container">
							<div className="grid-x grid-margin-x">
								<div className="cell small-12">
									<Background style={{ paddingTop: "22.0625rem" }} className={style.headline} overlay="linear-gradient(180deg, rgba(68,85,90,0) 0%, #050505 100%)" backgroundRepeat="repeat" backgroundSize="auto" width="calc(100% + 66px)" image="Repeatable-Texture.png">
										<div className={style.headlineWrapper}>
											<span>404</span>
											<h1>Page Not Found</h1>
										</div>
									</Background>
								</div>
							</div>
						</div>
					</Background>
					<main>{this.props.children}</main>
					<Footer />
				</Offcanvas>
			</>
		)
	}
}

export default NotFoundPage
